import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

import '../../scss/shop-nav.scss';

const ShopNav = () => {
	const {
	    allShopifyCollection: { collections }
	} = useStaticQuery(graphql`
	    query CollectionsQuery {
	        allShopifyCollection(sort: {order: DESC, fields: updatedAt}) {
	            collections: nodes {
	                title
	                handle
	            }
	        }
	    }
	`)

	const handleRedirect = (event) => {
		navigate('/shop/' + event.target.value);
	}

	return (
		<div className="shop-nav">
			<select class="shop-nav" onChange={handleRedirect}>
				<option value="/shop/" readonly selected disabled>Select category:</option>
				{collections && collections.map(collection => {
					return (
						<option value={collection.handle}>
							{collection.title}
						</option>
					)
				})}
			</select>
		</div>
	)
}

export default ShopNav
